<template>
	<div class="ExportacaoAdministrativa">
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs6>
				<h2 class="indigo--text text--darken-4">Exportações Administrativas</h2>
			</v-flex>
			<v-flex xs6 class="text-end">
			</v-flex>
		</v-layout>
		<v-layout row wrap class="mx-5 my-5">
			<v-flex xs12>
				<v-layout row wrap class="my-3 pa-3 grey lighten-4 rounded-lg">
					<v-flex xs3 class="pr-3">
						<v-autocomplete :items="basesDisponiveisComputed" item-text="text" item-value="value" label="Base a exportar" outlined dense v-model="base" clearable></v-autocomplete>
					</v-flex>
					<v-flex xs3 class="pr-3">
						<v-btn depressed dark color="indigo darken-4" :loading="loadingStatusExport" @click="buscaDadosExportacao()">
							<v-icon>file_download</v-icon>
							<span>Exportar</span>
						</v-btn>
					</v-flex>
				</v-layout>
			</v-flex>
		</v-layout>
		<v-snackbar v-model="snackbarShower" :timeout="2000" top :color="snackbarColor">
			<v-icon>verified_user</v-icon> {{snackbarText}}
			<template v-slot:action="{ attrs }">
				<v-btn v-bind="attrs" dark fab depressed small :color="snackbarColor" @click="snackbarShower = false">
					<v-icon small>close</v-icon>
				</v-btn>
			</template>
		</v-snackbar>
	</div>
</template>
<script>
import Excel from 'exceljs';
export default {
	data() {
		return {
			loadingStatusExport: false,
			base: '',
			basesDisponiveis: [
				'usuarios',
				'unidades_operacionais',
				'usuarios_com_suas_unidades_operacionais',
				'clientes_pessoa_fisica',
				'clientes_pessoa_juridica',
				'produtos',
				'feedbacks_pessoa_fisica',
				'feedbacks_pessoa_juridica',
				'vendas_pessoa_fisica',
				'vendas_pessoa_juridica',
				'contas_a_pagar'
			],
			basesCsv: [],
			basesXls: [
				'usuarios',
				'unidades_operacionais',
				'usuarios_com_suas_unidades_operacionais',
				'clientes_pessoa_fisica',
				'clientes_pessoa_juridica',
				'produtos',
				'feedbacks_pessoa_fisica',
				'feedbacks_pessoa_juridica',
				'vendas_pessoa_fisica',
				'vendas_pessoa_juridica',
				'contas_a_pagar'
			],
			numericHeaders: {
				usuarios: ['id_usuario', 'id_cargo', 'id_usuario_criador', 'id_usuario_alterador'],
				unidades_operacionais: ['id_unidade', 'id_unidade_operacional'],
				usuarios_com_suas_unidades_operacionais: ['id_unidade', 'id_unidade_operacional'],
				clientes_pessoa_fisica: ['id_cliente'],
				clientes_pessoa_juridica: ['id_cliente'],
				produtos : ['id_produto', 'valor'],
				feedbacks_pessoa_fisica: ['id_feedback', 'id_cliente', 'id_vendedor'],
				feedbacks_pessoa_juridica: ['id_feedback', 'id_cliente', 'id_vendedor'],
				vendas_pessoa_fisica: ['id_venda', 'id_cliente', 'id_vendedor', 'id_unidade_operacional', 'ano', 'id_produto', 'quantidade', 'valor_unitario', 'valor_total'],
				vendas_pessoa_juridica: ['id_venda', 'id_cliente', 'id_vendedor', 'id_unidade_operacional', 'ano', 'id_produto', 'quantidade', 'valor_unitario', 'valor_total'],
				contas_a_pagar: ['id_conta', 'id_tipo', 'valor', 'id_status']
			},
			snackbarColor: 'green',
			snackbarText: '',
			snackbarShower: false
		}
	},
	computed: {
		basesDisponiveisComputed () {
			var basesDisponiveis = [];

			this.basesDisponiveis.forEach(function(item, key){
				basesDisponiveis.push({
					text: item.replace(/_/g, ' ').charAt(0).toUpperCase() + item.replace(/_/g, ' ').slice(1),
					value: item
				});
			});

			return basesDisponiveis;
		}
	},
	methods: {
		buscaDadosExportacao() {
			if (this.base != '') {
				this.loadingStatusExport = true;
				
				var thisEl = this;
	  			thisEl.$http.post(//requisição ajax
	                'exportacao_administrativa',
	                {
	                	token: localStorage.getItem('token'),
	                	base: thisEl.base
	                },
	                { emulateJSON: true }
	            ).then(
	                function(r){//sucesso
	                	if (r.body.token == false) {
	                    	thisEl.snackbarColor = 'red';
		      				thisEl.snackbarText = r.body.message;
		      				thisEl.snackbarShower = true;
		      				
		      				setTimeout(function(){
	                    		thisEl.$router.push('/logout');
	                    	}, 2000);
	                    } else {
	                        if (r.body.success) {
	                        	thisEl.loadingStatusExport = false;

	                        	if (thisEl.basesXls.indexOf(thisEl.base) >= 0) {
									thisEl.exportXlsAjax(r.body.dados, 'listagem_customizada_' + thisEl.base, eval(`thisEl.numericHeaders.${thisEl.base}`));//dados: {headers: [{name:'fieldname'}], values [{fieldname: fieldvalue}]}
								} else {
									thisEl.exportCsvAjax(r.body.dados, 'listagem_customizada_' + thisEl.base);//dados: {headers: [{name:'fieldname'}], values [{fieldname: fieldvalue}]}
								}
	                        } else {
	                        	thisEl.loadingStatusExport = false;
	                        	thisEl.snackbarColor = 'red';
			      				thisEl.snackbarText = r.body.message;
			      				thisEl.snackbarShower = true;
	                        }
	                    }
					},
	                function(error){//erro na requisição, por exemplo 404
	                    thisEl.loadingStatusExport = false;
	                	thisEl.snackbarColor = 'red';
	      				thisEl.snackbarText = 'Houve um erro com a requisição.';
	      				thisEl.snackbarShower = true;
	                }
	            ).finally(function(){//executa este método após toda execução do then
	                //do something
	            });
			}
		},
		exportCsvAjax(dados, filename) {
			exportJsonToCsv(dados, filename);
		},
		exportXlsAjax(dados, filename, numericHeaders){
			var thisEl = this;
            const workbook = new Excel.Workbook()//novo arquivo
            const worksheet = workbook.addWorksheet('Sheet1')//nova sheet

            var headers = [];
	        for (var i = 0; i < dados.headers.length; i++) { 
	        	headers.push(dados.headers[i].name);
	        }

	        var lines = [];
			for (var i = 0; i < dados.values.length; i++) { 
				var line = [];
				for (var ii = 0; ii < headers.length; ii++) {
					let valueToPush = eval('dados.values[i].' + headers[ii]);
					valueToPush = ((valueToPush == null) ? '' : valueToPush);

					if (valueToPush != null && numericHeaders.indexOf(headers[ii]) >= 0) {
						valueToPush = Number(valueToPush);
					}
					
					line.push(valueToPush);
				}

				lines.push(line);
			}

            //adiciona linhas na sheet
            worksheet.addRows([headers])
            worksheet.addRows(lines)

            workbook.xlsx.writeBuffer().then(function(buffer){//cria buffer do arquivo
                const data = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});//cria arquivo em Blob
                
                var link = document.createElement('a');

                link.setAttribute('href', URL.createObjectURL(data));
                link.setAttribute('download', filename + '.xlsx');
                
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                
                link.click();
                document.body.removeChild(link);
            });
        }
	}
};
</script>
